'use client';

import { SORT_DIR } from '@sentinel/api/constants';
import classNames from 'classnames';
import { type ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

import { fetchListings } from '@/actions/listings';
// import { useIsVisible } from '@/components/useIsVisible';

export const LoadMoreListings = ({
  total,
  limit = 12,
  place,
  sort,
  dir,
}: {
  total: number;
  limit?: number;
  place?: string;
  sort: string;
  dir: SORT_DIR;
}) => {
  const container = useRef<HTMLDivElement>(null);
  // const lastVisible = useRef(false);
  // const visible = useIsVisible(container);
  const [hasMore, setHasMore] = useState(total > limit);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<{
    index: number;
    total: number;
    data: (ReactElement | undefined)[];
  }>({
    index: 1,
    total,
    data: [],
  });

  const loadMore = useCallback(() => {
    setLoading(true);
    fetchListings({ offset: data.index * limit, place, sort, dir }).then((res) => {
      setData((prev) => ({
        index: prev.index + 1,
        total: res.total,
        data: [...prev.data, ...res.data],
      }));

      setHasMore(res.total > (data.index + 1) * limit);
      setLoading(false);
    });
  }, [data.index, limit, place, sort, dir]);

  useEffect(() => {
    setData({ index: 1, total, data: [] });
    setHasMore(total > limit);
  }, [place, total, limit]);

  // useEffect(() => {
  //   if (hasMore && visible && !lastVisible.current) {
  //     lastVisible.current = true;
  //
  //     loadMore();
  //   }
  //
  //   if (!visible) {
  //     lastVisible.current = false;
  //   }
  // }, [visible, loadMore, hasMore]);

  return (
    <>
      {data.data}
      <div className="md:col-span-2 lg:col-span-3 mx-auto" ref={container}>
        {hasMore && (
          <button
            className={classNames('block btn', {
              'btn-primary': !loading,
              'cursor-not-allowed bg-gray-200': loading,
            })}
            disabled={loading}
            onClick={loadMore}>
            {!loading && 'Load More'}
            {loading && (
              <div className="flex space-x-2 items-center">
                <FaSpinner className="animate-spin" /> <div>Loading</div>
              </div>
            )}
          </button>
        )}
      </div>
    </>
  );
};
