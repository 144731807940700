'use client';

import L from 'leaflet';
import MarkerIcon from 'leaflet/dist/images/marker-icon.png';
import MarkerShadow from 'leaflet/dist/images/marker-shadow.png';
import Link from 'next/link';
import { type FC, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import 'leaflet/dist/leaflet.css';
import type { MapListing } from '@sentinel/api/types';

const mapCenter: [number, number] = [47.168869319237764, -28.929258892630003];

const defaultIcon = new L.Icon({
  iconUrl: MarkerIcon.src,
  iconRetinaUrl: MarkerIcon.src,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
  shadowUrl: MarkerShadow.src,
  shadowSize: [41, 41],
});

type Props = {
  listings: MapListing[];
  filterCountry?: string;
};

const Map: FC<Props> = ({ listings, filterCountry }) => {
  const map = useRef<L.Map | null>(null);

  useEffect(() => {
    if (filterCountry) {
      const bounds: [number, number][] = [];
      listings.forEach((listing) => {
        listing._embedded.addresses.forEach((address) => {
          if (address.country === filterCountry) {
            bounds.push([address.lat, address.lng]);
          }
        });
      });

      if (bounds.length > 0) {
        map.current?.flyToBounds(bounds, { maxZoom: 10, duration: 1.5 });
      }

      return;
    }

    map.current?.flyTo(mapCenter, 3, { duration: 1.5 });
  }, [filterCountry, listings]);

  const bounds: [number, number][] = [];
  if (filterCountry) {
    listings.forEach((listing) => {
      listing._embedded.addresses.forEach((address) => {
        if (address.country === filterCountry) {
          bounds.push([address.lat, address.lng]);
        }
      });
    });
  }

  const defaultCenter: { center: [number, number] } | { bounds: [number, number][] } =
    bounds.length > 0
      ? {
          bounds,
        }
      : {
          center: mapCenter,
        };
  return (
    <div>
      <MapContainer
        style={{
          height: '500px',
          width: '100vw',
        }}
        ref={map}
        zoom={3}
        boundsOptions={{ maxZoom: 10 }}
        {...defaultCenter}
        scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MarkerClusterGroup chunkedLoading showCoverageOnHover={false}>
          {listings.map((listing, idx) => {
            return listing._embedded.addresses.map((address, index) => (
              <Marker
                key={`${idx}-${index}`}
                icon={defaultIcon}
                position={[address.lat, address.lng]}>
                <Popup>
                  <div className="flex w-72 space-x-4">
                    <img
                      src={listing.profile_image || ''}
                      alt={listing.title}
                      className="w-[51px] h-[51px]"
                    />
                    <div>
                      <Link
                        prefetch={false}
                        href={`/listing/${listing.slug}`}
                        className="text-primary text-lg font-bold">
                        {listing.title}
                      </Link>
                      <div>{[address.city, address.region].filter(Boolean).join(', ')}</div>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ));
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default Map;
