'use client';

import { useRouter, NProgress } from '@sentinel/manana/router';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { type FC, useCallback, useEffect, useState } from 'react';
import { z } from 'zod';
import { parseSearchParams } from 'zod-search-params';

type Props = {
  countries: string[];
};

const schema = z.object({
  place: z.string().optional().catch(undefined),
});

export const SearchForm: FC<Props> = ({ countries }) => {
  const { push } = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const { place: paramsPlace } = parseSearchParams(schema, searchParams);

  const [place, setPlace] = useState(paramsPlace);

  const createQueryString = useCallback(
    (newParams: [string, string | undefined][]) => {
      const params = new URLSearchParams(searchParams);
      params.delete('page');
      newParams.forEach(([name, value]) => {
        if (value) {
          params.set(name, value);
        } else {
          params.delete(name);
        }
      });

      return params.toString();
    },
    [searchParams],
  );

  useEffect(() => {
    if (paramsPlace === place) {
      return;
    }

    NProgress.start();
    push(pathname + '?' + createQueryString([['place', place]]), { scroll: false });
  }, [paramsPlace, pathname, createQueryString, push, place]);

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-3 py-4 mb-4 items-center justify-center">
        <label className="w-full md:w-auto mb-0">
          <span className="sr-only">Choose Category</span>
          <select
            name="category"
            className="form-select w-full"
            value={place || ''}
            onChange={(e): void => {
              if (!e.target.value) {
                setPlace(undefined);
                return;
              }

              setPlace(e.target.value);
            }}>
            <option value="">Show All MODX Professionals</option>
            <optgroup label="Show by Country">
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </optgroup>
          </select>
        </label>

        <div>or</div>
        <div>
          <a href="https://modx.com/get-help" className="btn btn-primary w-full block">
            Get Help Finding a Pro
          </a>
        </div>
      </div>
      <div className="prose max-w-full text-center text-lg">
        Are you an experienced MODX freelancer or agency? <Link href="/about">Learn more</Link> and
        apply today.
      </div>
    </div>
  );
};
